import React from "react"
import video from "../assets/SoundbiteWalkthrough.mp4"
import Callout from '../components/callout'
import Layout from "../components/layout"
// import { Link } from "gatsby"
import "../components/sass/main.scss"
import SEO from "../components/seo"
import StoreBadges from "../components/store-badges"
import phone from "../images/Body.svg"







const IndexPage = () => (
  <Layout>

    <SEO title="Hiya there! 👋 " />
    <section className="hero">
      <div className="container hero__content">
        <h1>
          Soundbite is a podcast app <svg className="icon microphone" width="48" height="48" viewBox="0 0 54 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
              <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="27" y="39" width="9"
                height="16">
                <path
                  d="M35.184 52.6378C35.184 53.9793 33.6695 54.0426 31.8005 54.0426H31.3112C29.4423 54.0426 27.9277 53.9836 27.9277 52.6378V42.3356C27.9277 40.994 29.4423 39.9014 31.3112 39.9014H31.8005C33.6695 39.9014 35.184 40.9898 35.184 42.3356V52.6378Z"
                  fill="#455A64" />
              </mask>
              <g mask="url(#mask0)">
                <path
                  d="M35.184 52.6378C35.184 53.9793 33.6695 54.0426 31.8005 54.0426H31.3112C29.4423 54.0426 27.9277 53.9836 27.9277 52.6378V42.3356C27.9277 40.994 29.4423 39.9014 31.3112 39.9014H31.8005C33.6695 39.9014 35.184 40.9898 35.184 42.3356V52.6378Z"
                  fill="#455A64" />
                <path d="M30.7959 40.2764H27.75V54.0422H30.7959V40.2764Z" fill="#37474F" />
                <path
                  d="M34.1155 54.8137C34.1155 55.3115 33.7147 55.7123 33.2169 55.7165C32.7191 55.7165 32.3183 55.3157 32.3141 54.8179L32.293 42.6089C32.293 42.1111 32.6937 41.7103 33.1916 41.7061C33.6894 41.7061 34.0902 42.1068 34.0944 42.6046L34.1155 54.8137Z"
                  fill="#CFD8DC" />
              </g>
              <path d="M33.8734 35.2521L26.7051 36.8711L28.2627 43.7678L35.431 42.1488L33.8734 35.2521Z"
                fill="#546E7A" />
              <path
                d="M35.4285 42.1487C36.2762 41.9572 36.6147 40.2581 36.1845 38.3537C35.7544 36.4492 34.7185 35.0605 33.8708 35.252C33.0231 35.4434 32.6846 37.1425 33.1148 39.047C33.5449 40.9514 34.5808 42.3401 35.4285 42.1487Z"
                fill="#546E7A" />
              <path
                d="M28.2605 43.7649C29.1082 43.5734 29.4467 41.8743 29.0166 39.9699C28.5864 38.0654 27.5505 36.6767 26.7029 36.8682C25.8552 37.0596 25.5167 38.7587 25.9468 40.6632C26.3769 42.5677 27.4128 43.9563 28.2605 43.7649Z"
                fill="#546E7A" />
              <path
                d="M27.9483 42.3859C28.2846 42.3099 28.348 41.3217 28.0898 40.1785C27.8316 39.0354 27.3497 38.1703 27.0133 38.2462C26.677 38.3222 26.6136 39.3105 26.8718 40.4536C27.13 41.5968 27.6119 42.4619 27.9483 42.3859Z"
                fill="#37474F" />
              <path
                d="M33.8591 35.2527L26.6914 36.8727C27.5394 36.6829 28.5772 38.0708 29.0075 39.9735C29.4378 41.8762 29.1003 43.5763 28.2523 43.7662L35.42 42.1462C36.268 41.9563 36.6055 40.2562 36.1752 38.3535C35.7448 36.4508 34.707 35.0629 33.8591 35.2527Z"
                fill="#455A64" />
              <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="12" y="-1" width="29"
                height="40">
                <path
                  d="M40.0984 24.5235C41.8829 30.4256 38.5543 35.5007 33.0488 37.1629L30.7243 37.8632C25.2231 39.5254 19.6332 37.146 17.8487 31.2398L12.5837 13.8163C10.8034 7.92274 14.132 2.84759 19.6332 1.1854L21.9577 0.485088C27.459 -1.1771 33.0531 1.20228 34.8376 7.10431L40.0984 24.5235Z"
                  fill="#B0BEC5" />
              </mask>
              <g mask="url(#mask1)">
                <path
                  d="M40.0984 24.5235C41.8829 30.4256 38.5543 35.5007 33.0488 37.1629L30.7243 37.8632C25.2231 39.5254 19.6332 37.146 17.8487 31.2398L12.5837 13.8163C10.8034 7.92274 14.132 2.84759 19.6332 1.1854L21.9577 0.485088C27.459 -1.1771 33.0531 1.20228 34.8376 7.10431L40.0984 24.5235Z"
                  fill="#B0BEC5" />
                <path
                  d="M31.526 25.9621C34.5128 35.8509 33.8505 39.8334 29.29 41.2087L27.8261 41.6517C24.3667 42.6979 19.9581 38.2302 17.5281 30.1893L12.8875 14.8162C10.6685 7.47134 12.0058 1.76337 15.4652 0.721341L16.9291 0.278372C20.3885 -0.767878 23.8647 0.603216 26.8811 10.589L31.526 25.9621Z"
                  fill="#90A4AE" />
                <path
                  d="M28.0801 9.10373C28.2235 9.58045 27.9535 10.0825 27.481 10.2259L17.8833 13.1242C17.4066 13.2676 16.9046 12.9976 16.7612 12.5251C16.6177 12.0484 16.8877 11.5464 17.3602 11.403L26.9579 8.50467C27.4346 8.36123 27.9366 8.62701 28.0801 9.10373Z"
                  fill="#455A64" />
                <path
                  d="M29.6016 14.1496C29.745 14.6264 29.475 15.1284 29.0025 15.2718L19.4048 18.1701C18.9281 18.3135 18.4261 18.0435 18.2826 17.571C18.1392 17.0943 18.4092 16.5923 18.8817 16.4489L28.4794 13.5506C28.9561 13.4071 29.4581 13.6729 29.6016 14.1496Z"
                  fill="#455A64" />
                <path
                  d="M31.125 19.1952C31.2684 19.6719 30.9984 20.1739 30.5259 20.3174L20.9283 23.2157C20.4516 23.3591 19.9495 23.0891 19.8061 22.6166C19.6626 22.1399 19.9326 21.6378 20.4051 21.4944L30.0028 18.5961C30.4795 18.4527 30.9816 18.7227 31.125 19.1952Z"
                  fill="#455A64" />
                <path
                  d="M32.6523 24.2407C32.7958 24.7175 32.5258 25.2195 32.0533 25.3629L22.4556 28.2654C21.9789 28.4089 21.4769 28.1389 21.3334 27.6664C21.19 27.1896 21.46 26.6876 21.9325 26.5442L31.5301 23.6459C32.0069 23.4982 32.5089 23.7682 32.6523 24.2407Z"
                  fill="#455A64" />
                <path
                  d="M34.1758 29.2912C34.3192 29.7679 34.0492 30.2699 33.5767 30.4134L23.9791 33.3116C23.5023 33.4551 23.0003 33.1851 22.8569 32.7126C22.7134 32.2359 22.9834 31.7338 23.4559 31.5904L33.0536 28.6879C33.5303 28.5445 34.0323 28.8145 34.1758 29.2912Z"
                  fill="#455A64" />
                <path
                  d="M42.9587 2.64517C43.1823 3.08813 43.0009 3.62813 42.558 3.85173L33.5973 8.3447C33.1544 8.56829 32.6144 8.38688 32.3908 7.94391C32.1672 7.50095 32.3486 6.96095 32.7915 6.73735L41.7522 2.24438C42.1994 2.02501 42.7394 2.2022 42.9587 2.64517Z"
                  fill="#455A64" />
                <path
                  d="M44.4473 7.62337C44.6709 8.06633 44.4895 8.60634 44.0465 8.82993L35.0817 13.3229C34.6387 13.5465 34.0987 13.3651 33.8751 12.9221C33.6515 12.4791 33.833 11.9391 34.2759 11.7156L43.2365 7.22259C43.6837 6.99899 44.2237 7.17618 44.4473 7.62337Z"
                  fill="#455A64" />
                <path
                  d="M45.9376 12.597C46.1612 13.04 45.9798 13.58 45.5368 13.8036L36.5719 18.2965C36.129 18.5201 35.589 18.3387 35.3654 17.8957C35.1418 17.4528 35.3232 16.9128 35.7662 16.6892L44.7268 12.1962C45.174 11.9726 45.714 12.1498 45.9376 12.597Z"
                  fill="#455A64" />
                <path
                  d="M47.4216 17.5706C47.6452 18.0136 47.4638 18.5536 47.0208 18.7772L38.0602 23.2702C37.6173 23.4938 37.0773 23.3123 36.8537 22.8694C36.6301 22.4264 36.8115 21.8864 37.2544 21.6628L46.2151 17.1699C46.658 16.9463 47.198 17.1234 47.4216 17.5706Z"
                  fill="#455A64" />
                <path
                  d="M48.9122 22.5452C49.1358 22.9882 48.9544 23.5282 48.5114 23.7518L39.5465 28.2448C39.1036 28.4684 38.5636 28.287 38.34 27.844C38.1164 27.401 38.2978 26.861 38.7408 26.6374L47.7014 22.1445C48.1486 21.9209 48.6886 22.0981 48.9122 22.5452Z"
                  fill="#455A64" />
                <path
                  d="M14.6347 13.2809C14.7317 13.7703 14.4153 14.2428 13.9259 14.3398L4.09202 16.2888C3.60265 16.3859 3.13015 16.0695 3.03311 15.5801C2.93608 15.0907 3.25249 14.6182 3.74186 14.5212L13.5758 12.5679C14.0609 12.4751 14.5376 12.7915 14.6347 13.2809Z"
                  fill="#455A64" />
                <path
                  d="M16.1366 18.305C16.2337 18.7944 15.9173 19.2669 15.4279 19.3639L5.59397 21.3172C5.1046 21.4142 4.6321 21.0978 4.53507 20.6084C4.43804 20.119 4.75444 19.6465 5.24382 19.5495L15.0777 17.5962C15.5671 17.5034 16.0396 17.8198 16.1366 18.305Z"
                  fill="#455A64" />
                <path
                  d="M17.6386 23.3343C17.7356 23.8237 17.4192 24.2962 16.9298 24.3932L7.09593 26.3465C6.60655 26.4435 6.13405 26.1271 6.03702 25.6377C5.93999 25.1483 6.2564 24.6758 6.74577 24.5788L16.5797 22.6255C17.0691 22.5327 17.5416 22.8491 17.6386 23.3343Z"
                  fill="#455A64" />
                <path
                  d="M19.1444 28.363C19.2415 28.8523 18.9251 29.3248 18.4357 29.4219L8.60179 31.3751C8.11241 31.4722 7.63991 31.1558 7.54288 30.6664C7.44585 30.177 7.76226 29.7045 8.25163 29.6075L18.0855 27.6542C18.5749 27.5572 19.0474 27.8736 19.1444 28.363Z"
                  fill="#455A64" />
                <path
                  d="M20.6464 33.3913C20.7434 33.8807 20.427 34.3532 19.9376 34.4502L10.1037 36.4035C9.61436 36.5005 9.14186 36.1841 9.04483 35.6947C8.9478 35.2053 9.26421 34.7328 9.75358 34.6358L19.5875 32.6825C20.0769 32.5855 20.5494 32.9019 20.6464 33.3913Z"
                  fill="#455A64" />
                <path
                  d="M36.1704 24.7849C36.3138 25.2616 36.0438 25.7636 35.5713 25.9071C35.0946 26.0505 34.5926 25.7805 34.4491 25.308L29.433 8.69878C29.2896 8.22206 29.5596 7.72003 30.0321 7.57659C30.5088 7.43315 31.0108 7.70315 31.1543 8.17565L36.1704 24.7849Z"
                  fill="white" />
                <path
                  d="M37.2458 28.3583C37.3892 28.8308 37.1192 29.3328 36.6467 29.4762C36.1742 29.6197 35.6722 29.3497 35.5287 28.8772C35.3853 28.4047 35.6553 27.9026 36.1278 27.7592C36.6003 27.6158 37.1023 27.8858 37.2458 28.3583Z"
                  fill="white" />
              </g>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="54" height="48" fill="white" />
              </clipPath>
            </defs>
          </svg> to help you find hidden gems <svg className="icon sparkle" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.0759 30.6901C35.1384 29.5051 33.6234 27.9301 31.3809 26.4788C33.6234 25.0276 35.1346 23.4526 36.0759 22.2676C37.0134 23.4526 38.5284 25.0276 40.7671 26.4788C38.5284 27.9301 37.0171 29.5051 36.0759 30.6901Z"
              fill="#FCC21B" />
            <path
              d="M7.2832 16.8005C11.7532 13.6393 14.5207 9.99805 16.0357 7.56055C17.5507 9.99805 20.3182 13.6355 24.7882 16.8005C20.322 19.9618 17.5507 23.5993 16.0357 26.0368C14.5245 23.603 11.7532 19.9618 7.2832 16.8005Z"
              fill="#FCC21B" />
            <path
              d="M23.8916 41.4006C23.1116 40.2569 21.9491 38.8431 20.3066 37.5381C21.9491 36.2294 23.1116 34.8194 23.8916 33.6719C24.6716 34.8156 25.8341 36.2294 27.4766 37.5381C25.8341 38.8431 24.6716 40.2569 23.8916 41.4006Z"
              fill="#FCC21B" />
            <path
              d="M17.5133 30.0265C17.5433 29.9553 20.547 22.954 28.5383 18.1728L30.8333 16.8003L28.5383 15.424C20.547 10.6465 17.5433 3.64527 17.517 3.57777L16.047 0.0302734L14.562 3.57402C14.532 3.64527 11.5283 10.6465 3.53703 15.4278L1.23828 16.8003L3.53328 18.1728C11.5245 22.954 14.5283 29.9553 14.5583 30.0265L16.0283 33.5665L17.5133 30.0265ZM7.28328 16.8003C11.7533 13.639 14.5208 9.99777 16.0358 7.56027C17.5508 9.99777 20.3183 13.6353 24.7883 16.8003C20.322 19.9615 17.5508 23.599 16.0358 26.0365C14.5245 23.6028 11.7533 19.9615 7.28328 16.8003Z"
              fill="#F79329" />
            <path
              d="M44.2212 25.2223C39.2262 22.7548 37.3437 19.1736 37.3287 19.1436L36.0874 16.6836L34.8274 19.1398C34.8087 19.1736 32.9262 22.7548 27.9312 25.2261L25.3887 26.4823L27.9312 27.7386C32.9149 30.2023 34.8012 33.7686 34.8274 33.8248L36.0649 36.2811L37.3249 33.8248C37.3437 33.7911 39.2262 30.2098 44.2212 27.7386L46.7637 26.4823L44.2212 25.2223ZM36.0762 30.6898C35.1387 29.5048 33.6237 27.9298 31.3812 26.4786C33.6237 25.0273 35.1349 23.4523 36.0762 22.2673C37.0137 23.4523 38.5287 25.0273 40.7674 26.4786C38.5287 27.9298 37.0174 29.5048 36.0762 30.6898Z"
              fill="#F79329" />
            <path
              d="M25.1937 30.308L23.9075 27.1055L22.5987 30.3042C22.5837 30.338 21.0875 33.908 17.1462 36.3455L15.2188 37.538L17.1462 38.7267C21.0687 41.153 22.5687 44.6967 22.5987 44.768L23.885 47.9667L25.1937 44.768C25.2087 44.7305 26.7012 41.1642 30.6462 38.7267L32.5737 37.538L30.6462 36.3455C26.7012 33.908 25.205 30.3417 25.1937 30.308ZM23.8925 41.4005C23.1125 40.2567 21.95 38.843 20.3075 37.538C21.95 36.2292 23.1125 34.8192 23.8925 33.6717C24.6725 34.8155 25.835 36.2292 27.4775 37.538C25.835 38.843 24.6725 40.2567 23.8925 41.4005Z"
              fill="#F79329" />
            <path
              d="M17.5133 30.0265C17.5433 29.9553 20.547 22.954 28.5383 18.1728L30.8333 16.8003L28.5383 15.424C20.547 10.6465 17.5433 3.64527 17.517 3.57777L16.047 0.0302734L14.562 3.57402C14.532 3.64527 11.5283 10.6465 3.53703 15.4278L1.23828 16.8003L3.53328 18.1728C11.5245 22.954 14.5283 29.9553 14.5583 30.0265L16.0283 33.5665L17.5133 30.0265ZM7.28328 16.8003C11.7533 13.639 14.5208 9.99777 16.0358 7.56027C17.5508 9.99777 20.3183 13.6353 24.7883 16.8003C20.322 19.9615 17.5508 23.599 16.0358 26.0365C14.5245 23.6028 11.7533 19.9615 7.28328 16.8003Z"
              fill="#F79329" />
            <path
              d="M44.2212 25.2223C39.2262 22.7548 37.3437 19.1736 37.3287 19.1436L36.0874 16.6836L34.8274 19.1398C34.8087 19.1736 32.9262 22.7548 27.9312 25.2261L25.3887 26.4823L27.9312 27.7386C32.9149 30.2023 34.8012 33.7686 34.8274 33.8248L36.0649 36.2811L37.3249 33.8248C37.3437 33.7911 39.2262 30.2098 44.2212 27.7386L46.7637 26.4823L44.2212 25.2223ZM36.0762 30.6898C35.1387 29.5048 33.6237 27.9298 31.3812 26.4786C33.6237 25.0273 35.1349 23.4523 36.0762 22.2673C37.0137 23.4523 38.5287 25.0273 40.7674 26.4786C38.5287 27.9298 37.0174 29.5048 36.0762 30.6898Z"
              fill="#F79329" />
            <path
              d="M24.975 30.2025L23.6887 27L22.38 30.1987C22.365 30.2325 20.8687 33.8025 16.9275 36.24L15 37.4325L16.9275 38.6213C20.85 41.0475 22.35 44.5912 22.38 44.6625L23.6662 47.8613L24.975 44.6625C24.99 44.625 26.4825 41.0588 30.4275 38.6213L32.355 37.4325L30.4275 36.24C26.4825 33.8025 24.9862 30.2363 24.975 30.2025ZM23.6737 41.295C22.8937 40.1513 21.7312 38.7375 20.0887 37.4325C21.7312 36.1238 22.8937 34.7138 23.6737 33.5663C24.4537 34.71 25.6162 36.1238 27.2587 37.4325C25.6162 38.7375 24.4537 40.1513 23.6737 41.295Z"
              fill="#F79329" />
          </svg> through bite-sized listens <svg className="icon ear" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M38.4267 16.429C38.4267 8.45868 31.9661 1.99805 23.9958 1.99805C16.0255 1.99805 9.57422 8.45868 9.57422 16.429C9.57422 17.7886 9.76176 19.1108 10.1181 20.3579C10.4181 22.7115 10.7276 25.5714 10.7276 27.3249C10.7276 30.7005 10.8964 36.0266 10.8964 36.0266C9.95867 42.009 14.4877 45.9566 20.5545 45.9566C26.6213 45.9566 29.6313 41.784 31.5254 36.0266C31.6848 35.5296 31.7973 35.0514 31.8629 34.5732C33.7664 27.4843 38.4267 26.4059 38.4267 16.429Z"
              fill="#FFD3B6" />
            <path fillRule="evenodd" clipRule="evenodd"
              d="M14.7905 26.95C14.8057 26.2271 15.404 25.6535 16.1268 25.6687C17.7025 25.702 20.0534 26.3439 21.8348 27.8945C23.7153 29.5313 24.8708 32.1143 23.9588 35.6704C23.7792 36.3707 23.0658 36.7928 22.3655 36.6132C21.6652 36.4336 21.2431 35.7203 21.4227 35.0199C22.0839 32.4418 21.2642 30.8688 20.1159 29.8693C18.8685 28.7836 17.137 28.3088 16.0717 28.2863C15.3488 28.2711 14.7752 27.6728 14.7905 26.95Z"
              fill="#D8B09A" />
            <path fillRule="evenodd" clipRule="evenodd"
              d="M16.882 11.8767C15.0498 14.8818 14.4009 18.6739 15.2202 21.4472C15.4251 22.1406 15.0291 22.8687 14.3357 23.0736C13.6424 23.2784 12.9142 22.8824 12.7094 22.189C11.6378 18.5623 12.5156 14.0088 14.6465 10.5138C16.7888 7 20.4197 4.23283 25.0352 4.80982C28.2289 5.20908 30.6423 6.40315 32.3575 8.1429C34.0635 9.87332 34.9915 12.0544 35.3812 14.2908C36.1505 18.7046 34.8698 23.5842 32.9451 26.4712C30.3031 30.4341 28.9172 36.058 28.3338 38.6836C28.1769 39.3893 27.4776 39.8343 26.7719 39.6775C26.0661 39.5207 25.6211 38.8214 25.7779 38.1156C26.3581 35.5048 27.8231 29.4342 30.7666 25.0189C32.3328 22.6695 33.4515 18.4674 32.8019 14.7404C32.4823 12.9064 31.7464 11.2523 30.493 9.98103C29.2489 8.7191 27.4075 7.74496 24.7104 7.40778C21.4713 7.00286 18.7027 8.89035 16.882 11.8767Z"
              fill="#D8B09A" />
            <path fillRule="evenodd" clipRule="evenodd"
              d="M20.8198 27.8633C21.5105 28.0769 21.8974 28.8099 21.6838 29.5007C21.0668 31.4964 20.1316 33.3268 18.9608 34.7396C17.7996 36.1408 16.3222 37.2273 14.6133 37.5097C13.9 37.6276 13.2261 37.145 13.1082 36.4316C12.9903 35.7183 13.473 35.0445 14.1863 34.9266C15.0605 34.7821 16.0234 34.1809 16.9449 33.069C17.8568 31.9686 18.6467 30.4604 19.1824 28.7273C19.396 28.0366 20.1291 27.6498 20.8198 27.8633Z"
              fill="#D8B09A" />
          </svg>
        </h1>

        <StoreBadges></StoreBadges>

        <Callout data={["Join our ", <a href='https://teamsoundbite.slack.com/join/shared_invite/enQtNzYyNzAxNTc0NzM3LWMyNWQxMjQ2ZDliNWQzNDI1MzU1NjIxMWQyODU5ZTE3NDZhOGMzNGQ3NDAzOTViZTY2ZDg3NTgwMGM3Nzg3NjE'>Slack Community</a>, " to tell us what’s on your mind & what you’d like to see"]} />

      </div>
      <div className="appvideo">
        <div className="container appvideo__content">
          <video src={video} height="715" width="330" loop autoPlay type="video/mp4" playsInline>       <track default kind="captions" srclang="en" />
          </video>
          <img src={phone} alt="Phone Body"></img>
        </div>
      </div>
    </section>


  </Layout>
)

export default IndexPage
